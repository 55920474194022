import {
	Alert,
	AsyncSelect,
	Badge,
	Box,
	Button,
	Icons,
	Select,
	Table,
	Stack,
	Heading,
	Text,
	Container,
	Inline,
	Time,
	RelativeTime,
	SelectInput,
	TableDataCell,
	Col,
	Component,
	Grid,
	joinAttributes,
	Spinner,
	TabContent,
	TabItem,
	Tabs,
	TabsList,
	Money,
} from "@sembark-travel/ui/base"
import { showSnackbar } from "@sembark-travel/ui/snackbar"
import { Dialog } from "@sembark-travel/ui/dialog"
import {
	utcTimestampToLocalDate,
	dateToQuery,
	parseDateFromQuery,
	formatDate,
	subtractUnit,
	getUnit,
	isSame,
	startOf,
	dateToUTCString,
	endOf,
	isSameOrAfter,
	addUnit,
	isAfter,
	isBefore,
} from "@sembark-travel/datetime-utils"
import { numberToLocalString } from "@sembark-travel/number-utils"
import { useLocationQuery, queryToSearch } from "@sembark-travel/ui/router"
import { useSearch, TSearchParams } from "@sembark-travel/ui/list"
import { useXHR } from "@sembark-travel/xhr"
import pluralize from "pluralize"
import React, { useEffect, useMemo, useState } from "react"
import useSWR from "swr"
import * as Validator from "yup"
import { Email } from "../../Contacts"
import { IPermission } from "../../Roles"
import { ActionMenu, ResendInvitation } from "./List"
import { ITenant, TenantsXHR } from "./store"
import {
	Form,
	SelectField,
	withServerErrors,
	SubmissionError,
	validateFormValues,
} from "@sembark-travel/ui/form"
import { ActivityLogs } from "../../ActivityLogs"
import { PERMISSIONS, useCheckPermissions } from "../../Auth"
import { SelectUsers } from "../../Users"
import { collect } from "../../utils"
import {
	StoreTenantSubscriptionDetailsInDialog,
	UpdateTenantSubscriptionDetailsInDialog,
} from "./SubscriptionDetails"
import { isZeroMoney } from "@sembark-travel/money"

export function TenantDetailsOverview({
	tenant,
	refresh,
}: {
	tenant: ITenant
	refresh: () => void
}) {
	const {
		users_count,
		active_users_today,
		conversion_detail,
		is_in_trial,
		is_disabled,
		disabled_at,
		disabled_reason,
		disabled_by,
		profile_image_url,
		users,
		supported_by_users,
		owners,
		deleted_at,
		on_hold_at,
		on_hold_by,
		deleted_by,
		conversion_status,
	} = tenant
	const xhr = useXHR()
	const { hasPermission } = useCheckPermissions()

	const canManageTenants = hasPermission(PERMISSIONS.MANAGE_TENANTS)

	return (
		<Box>
			{deleted_at ? (
				<Alert status="error" title="Account Deleted" rounded="none">
					This account has been deleted by {deleted_by?.name},{" "}
					<RelativeTime timestamp={deleted_at} />.
				</Alert>
			) : disabled_at ? (
				<Alert
					status="warning"
					title={"Access Disabled - " + disabled_reason}
					rounded="none"
				>
					by {disabled_by?.name}, <RelativeTime timestamp={disabled_at} />
				</Alert>
			) : null}
			<Container bgColor="default" paddingY="4" borderBottomWidth="1">
				<Inline gap="4">
					<Box flex="1" minWidth="0">
						<Grid gap="8">
							<Col sm={12} md={8} lg={9}>
								<Stack gap="4">
									<Inline gap="4" collapseBelow="sm">
										<Box>
											{profile_image_url ? (
												<Box>
													<Box
														as="img"
														src={profile_image_url}
														style={{
															width: "100%",
															maxWidth: "200px",
															maxHeight: "100px",
															background: "white",
														}}
														alt="Logo"
													/>
												</Box>
											) : (
												<Box
													style={{ width: "50px", height: "50px" }}
													display="flex"
													justifyContent="center"
													alignItems="center"
													bgColor="inset"
													fontWeight="semibold"
												>
													{tenant.name[0]}
												</Box>
											)}
										</Box>
										<Stack gap="1">
											<Heading as="h3">{tenant.name}</Heading>
											<Text color="muted" fontSize="sm">
												Short Name: {tenant.short_name}
											</Text>
											<Text>
												{joinAttributes(
													tenant.functional_currency,
													`${tenant.timezone} (GMT${tenant.timezone_offset_string})`
												)}
											</Text>
											{is_in_trial ? (
												<Box>
													<Badge>In Trial</Badge>
												</Box>
											) : null}
										</Stack>
									</Inline>
									<Inline gap="8" flexWrap="wrap">
										{tenant.is_open_signup ? (
											<>
												<Attribute prop="Signup">Open Signup</Attribute>
											</>
										) : tenant.invited_at ? (
											<>
												<Attribute prop="Created By">
													<Time timestamp={tenant.created_at}>
														{tenant.created_by?.name}
													</Time>
												</Attribute>
												<Attribute prop="Invited At">
													<Time
														value={utcTimestampToLocalDate(tenant.invited_at)}
													/>
												</Attribute>
											</>
										) : null}
										<Attribute prop="Signed up At">
											{tenant.signup_at ? (
												<Time
													value={utcTimestampToLocalDate(tenant.signup_at)}
												/>
											) : (
												"Not signedup!"
											)}
										</Attribute>
										{!tenant.signup_at ? (
											<Attribute prop="Email">{tenant.address_email}</Attribute>
										) : null}
										<Attribute prop="Users">{users_count || 0}</Attribute>
										<Attribute prop="Active Today">
											{active_users_today || 0}
										</Attribute>
										<Component initialState={false}>
											{({ state, setState }) => (
												<Attribute
													prop={
														<Inline gap="2">
															<Box>Owners</Box>
															{users?.length &&
															!deleted_at &&
															canManageTenants ? (
																<Button inline onClick={() => setState(true)}>
																	<Icons.Pencil size="3" />
																</Button>
															) : null}
														</Inline>
													}
												>
													{owners?.length ? (
														joinAttributes(
															...owners.map((owner) => (
																<Box key={owner.id} display="inline">
																	{owner.name}{" "}
																	<Email iconOnly value={owner.email} />
																</Box>
															))
														)
													) : (
														<Box color="muted" fontSize="sm">
															Not Set
														</Box>
													)}
													{state ? (
														<>
															<Dialog
																open
																sm
																onClose={() => setState(false)}
																title="Update Owners"
															>
																<Form<{ owners: Required<ITenant>["owners"] }>
																	initialValues={{ owners: owners || [] }}
																	validate={validateFormValues(
																		Validator.object().shape({
																			owners: Validator.array().min(
																				1,
																				"Please select atleast one owner"
																			),
																		})
																	)}
																	onSubmit={withServerErrors(async (values) => {
																		if (
																			window.confirm(
																				'Please note that roles for the selected users will be updated to "Admin". Are you sure you want ton continue ?'
																			)
																		) {
																			await xhr.post(
																				`/tenants/${tenant.uid}/owners`,
																				{
																					owners: values.owners.map(
																						(o) => o.uid
																					),
																				}
																			)
																			refresh()
																			setState(false)
																		}
																	})}
																	subscription={{ submitting: true }}
																>
																	{({ submitting, handleSubmit }) => (
																		<form noValidate onSubmit={handleSubmit}>
																			<Dialog.Body>
																				<Stack gap="4">
																					<SelectField
																						select={Select}
																						name="owners"
																						label="Owners"
																						options={users}
																						multiple
																					/>
																					<Alert
																						status="warning"
																						title="Selected owners will be assigned the admin role as well"
																					/>
																				</Stack>
																			</Dialog.Body>
																			<Dialog.Footer>
																				<Stack gap="4">
																					<SubmissionError />
																					<Inline gap="4">
																						<Button
																							type="submit"
																							disabled={submitting}
																						>
																							{submitting
																								? "Updating..."
																								: "Update"}
																						</Button>
																						<Button
																							onClick={() => setState(false)}
																							disabled={submitting}
																						>
																							Cancel
																						</Button>
																					</Inline>
																				</Stack>
																			</Dialog.Footer>
																		</form>
																	)}
																</Form>
															</Dialog>
														</>
													) : null}
												</Attribute>
											)}
										</Component>
										<Component initialState={false}>
											{({ state, setState }) => (
												<Attribute
													prop={
														<Inline gap="2">
															<Box>Supported By</Box>
															{!deleted_at && canManageTenants ? (
																<Button inline onClick={() => setState(true)}>
																	<Icons.Pencil size="3" />
																</Button>
															) : null}
														</Inline>
													}
												>
													{supported_by_users?.length ? (
														joinAttributes(
															...supported_by_users.map((owner) => (
																<Box key={owner.id} display="inline">
																	{owner.name}{" "}
																	<Email iconOnly value={owner.email} />
																</Box>
															))
														)
													) : (
														<Box color="muted" fontSize="sm">
															Not Set
														</Box>
													)}
													{state ? (
														<>
															<Dialog
																open
																sm
																onClose={() => setState(false)}
																title="Update Application Support Members"
															>
																<Form<{
																	users: Required<ITenant>["supported_by_users"]
																}>
																	initialValues={{
																		users: supported_by_users || [],
																	}}
																	validate={validateFormValues(
																		Validator.object().shape({
																			users: Validator.array(),
																		})
																	)}
																	onSubmit={withServerErrors(async (values) => {
																		await xhr.post(
																			`/tenants/${tenant.uid}/supported-by-users`,
																			{
																				users: (values.users || []).map(
																					(o) => o.uid
																				),
																			}
																		)
																		refresh()
																		setState(false)
																	})}
																	subscription={{ submitting: true }}
																>
																	{({ submitting, handleSubmit }) => (
																		<form noValidate onSubmit={handleSubmit}>
																			<Dialog.Body>
																				<Stack gap="4">
																					<SelectField
																						select={SelectUsers}
																						name="users"
																						label="Support Users"
																						multiple
																						fetchOnMount
																					/>
																				</Stack>
																			</Dialog.Body>
																			<Dialog.Footer>
																				<Stack gap="4">
																					<SubmissionError />
																					<Inline gap="4">
																						<Button
																							type="submit"
																							disabled={submitting}
																						>
																							{submitting
																								? "Updating..."
																								: "Update"}
																						</Button>
																						<Button
																							onClick={() => setState(false)}
																							disabled={submitting}
																						>
																							Cancel
																						</Button>
																					</Inline>
																				</Stack>
																			</Dialog.Footer>
																		</form>
																	)}
																</Form>
															</Dialog>
														</>
													) : null}
												</Attribute>
											)}
										</Component>
									</Inline>
								</Stack>
							</Col>
							<Col>
								<Stack gap="4">
									{on_hold_at && on_hold_by ? (
										<Inline>
											<Alert status="warning">
												<Text fontWeight="semibold">
													On Hold: By {on_hold_by.name}, on{" "}
													<Time timestamp={on_hold_at} />
												</Text>
											</Alert>
										</Inline>
									) : null}
									{canManageTenants ? (
										<>
											{conversion_detail ? (
												<Stack gap="1">
													<Inline gap="6">
														<Inline gap="2">
															<Badge warning>
																{conversion_detail.billing_type}
															</Badge>
															<Badge>{conversion_detail.currency}</Badge>
															<UpdateTenantSubscriptionDetailsInDialog
																tenant={tenant}
																onSuccess={refresh}
																subscriptionDetail={conversion_detail}
															>
																{({ convert }) => (
																	<Button onClick={convert} inline>
																		<Icons.Pencil />
																	</Button>
																)}
															</UpdateTenantSubscriptionDetailsInDialog>
															<Button
																inline
																status="warning"
																onClick={() => {
																	if (
																		window.confirm(
																			"Are you sure you want to delete this subscription detail?"
																		)
																	) {
																		xhr
																			.delete(
																				`/tenant-conversion-details/${conversion_detail.id}`
																			)
																			.then(() => {
																				refresh()
																			})
																	}
																}}
															>
																<Icons.Trash color="warning" />
															</Button>
														</Inline>
														<StoreTenantSubscriptionDetailsInDialog
															tenant={tenant}
															onSuccess={refresh}
															initialValues={{
																valid_from_date:
																	conversion_detail.valid_till_date
																		? addUnit(
																				utcTimestampToLocalDate(
																					conversion_detail.valid_till_date
																				),
																				1,
																				"day"
																			)
																		: new Date(),
															}}
														>
															{({ convert }) => (
																<Button onClick={convert} inline>
																	<Icons.Plus />
																</Button>
															)}
														</StoreTenantSubscriptionDetailsInDialog>
													</Inline>
													<Box fontWeight="semibold" fontSize="md">
														{!conversion_detail.base_users ? (
															isZeroMoney(
																conversion_detail.minimum_amount
															) ? null : (
																<>
																	Min:{" "}
																	<Money
																		money={conversion_detail.minimum_amount}
																	/>
																	{" • "}
																</>
															)
														) : (
															<>
																{conversion_detail.base_amount ? (
																	<Money
																		money={conversion_detail.base_amount}
																	/>
																) : (
																	"0"
																)}
																{" for "}
																{pluralize(
																	"User",
																	conversion_detail.base_users,
																	true
																)}
																{" + "}
															</>
														)}
														<Money money={conversion_detail.amount_per_unit} />{" "}
														/{" "}
														{pluralize(
															"User",
															conversion_detail.users_per_unit || 0,
															true
														)}
													</Box>
													<Inline gap="1" alignItems={"center"}>
														<Inline alignItems={"center"} title="Validity">
															<Icons.Calendar />
														</Inline>
														<Text fontSize="sm">
															<Time
																timestamp={conversion_detail.valid_from_date}
															/>{" "}
															-{" "}
															{conversion_detail.valid_till_date ? (
																<Time
																	timestamp={conversion_detail.valid_till_date}
																/>
															) : (
																"Always"
															)}
														</Text>
														{conversion_detail.valid_till_users ? (
															<>
																<Text>•</Text>
																<Inline alignItems={"center"} title="Validity">
																	<Icons.UserGroup />
																</Inline>
																<Text fontSize="sm" title="Valid till Users">
																	{conversion_detail.valid_till_users}
																</Text>
															</>
														) : null}
														{conversion_detail.base_users &&
														!isZeroMoney(conversion_detail.minimum_amount) ? (
															<>
																<Text>•</Text>
																<Text fontSize="sm">
																	Min:{" "}
																	<Money
																		money={conversion_detail.minimum_amount}
																	/>
																</Text>
															</>
														) : null}
													</Inline>
												</Stack>
											) : !tenant.on_hold_at ? (
												<Stack gap="2" alignItems={"end"}>
													<StoreTenantSubscriptionDetailsInDialog
														tenant={tenant}
														onSuccess={refresh}
													>
														{({ convert }) => (
															<Box>
																<Button
																	onClick={convert}
																	size="sm"
																	level="secondary"
																	status="primary"
																>
																	<Icons.Plus /> Subscription Details
																</Button>
															</Box>
														)}
													</StoreTenantSubscriptionDetailsInDialog>
													{conversion_status === "converted" ? (
														<Text
															color="warning"
															fontSize="sm"
															fontWeight="semibold"
														>
															No active/valid subscription details Today+Users!
														</Text>
													) : null}
												</Stack>
											) : null}
										</>
									) : null}
								</Stack>
							</Col>
						</Grid>
					</Box>
					<Box>
						<ActionMenu tenant={tenant} onChange={refresh} />
					</Box>
				</Inline>
			</Container>
			{!tenant.signup_at &&
			!is_disabled &&
			tenant.invited_at &&
			canManageTenants ? (
				<Container paddingY="4">
					<Alert status="warning" title="Not signedup yet">
						<Stack gap="2">
							<Text>
								{tenant.name} was invited{" "}
								<RelativeTime timestamp={tenant.invited_at} /> but has not
								signedup yet.
							</Text>
							<Box>
								<ResendInvitation tenant={tenant} onChange={() => refresh()} />
							</Box>
						</Stack>
					</Alert>
				</Container>
			) : null}
		</Box>
	)
}

export function Modules({
	tenant,
	onChange,
}: {
	tenant: ITenant
	onChange: () => void
}) {
	const { roles, permissions, deleted_at } = tenant
	const xhr = useXHR()
	const { hasPermission } = useCheckPermissions()
	return (
		<Container paddingY="8">
			<Inline collapseBelow="md" gap="8">
				<Box width={{ xs: "full", md: "1/2" }}>
					<Stack gap="4">
						<Inline gap="4" alignItems="center">
							<Heading fontSize="md">Permissions</Heading>
							{!deleted_at && hasPermission(PERMISSIONS.MANAGE_TENANTS) ? (
								<Component initialState={false}>
									{({ state, setState }) => (
										<>
											<Button inline onClick={() => setState(true)}>
												<Icons.Pencil />
											</Button>
											<Dialog
												open={state}
												onClose={() => setState(false)}
												title={`Edit Permissions for ${tenant.short_name}`}
											>
												<Form<{ permissions: Array<string> }>
													initialValues={{
														permissions: permissions?.map((p) => p.name),
													}}
													onSubmit={withServerErrors(async (values) => {
														await xhr.post("/tenant-permissions", {
															tenant_ids: [tenant.id],
															permissions: values.permissions,
														})
														onChange()
														setState(false)
													})}
													subscription={{ submitting: true }}
												>
													{({ submitting, handleSubmit }) => (
														<form noValidate onSubmit={handleSubmit}>
															<Dialog.Body>
																<SelectField
																	label="Select Permissions"
																	name="permissions"
																	select={AsyncSelect}
																	fetchOnMount
																	multiple
																	fetch={(q) =>
																		xhr
																			.get("/tenant-permissions", {
																				params: { q },
																			})
																			.then((resp) =>
																				resp.data.data.map(
																					(p: IPermission) => p.name
																				)
																			)
																	}
																/>
															</Dialog.Body>
															<Dialog.Footer>
																<Stack gap="4">
																	<SubmissionError />
																	<Inline gap="4">
																		<Button type="submit" disabled={submitting}>
																			{submitting
																				? "Updating..."
																				: "Update Permissions"}
																		</Button>
																		<Button
																			disabled={submitting}
																			onClick={() => setState(false)}
																		>
																			Cancel
																		</Button>
																	</Inline>
																</Stack>
															</Dialog.Footer>
														</form>
													)}
												</Form>
											</Dialog>
										</>
									)}
								</Component>
							) : null}
						</Inline>
						<Inline gap="2" flexWrap="wrap">
							{permissions?.map((p) => <Badge key={p.id}>{p.name}</Badge>)}
						</Inline>
					</Stack>
				</Box>
				<Box flex="1">
					<Stack gap="4">
						<Heading as="h3" fontSize="md">
							Roles
						</Heading>
						<Inline gap="2" flexWrap="wrap">
							{roles?.map((r) => <Badge key={r.id}>{r.name}</Badge>)}
						</Inline>
					</Stack>
				</Box>
			</Inline>
		</Container>
	)
}

type TFilters = TSearchParams & {
	month: Date
}

type TFiltersInQuery = TSearchParams & {
	month?: string
}

function filtersToQuery(filters: TFilters): TFiltersInQuery {
	const { q, month, page } = filters
	const query: TFiltersInQuery = {
		month: dateToQuery(month || new Date(), "YYYY-MM"),
	}
	if (q) {
		query.q = q
	}
	if (page) query.page = page
	return query
}

function queryToFilters(query: TFiltersInQuery): TFilters {
	const { q, month, page } = query
	const filters: TFilters = {
		month: month
			? parseDateFromQuery(month + "-01")
			: startOf(new Date(), "month"),
	}
	if (q) {
		filters.q = q
	}
	if (page) filters.page = page
	return filters
}

export function Billing({
	tenantUid,
	signupAt,
}: {
	tenantUid: string
	signupAt: Date
}) {
	const [query, setQuery] = useLocationQuery({
		toQuery: filtersToQuery,
		fromQuery: queryToFilters,
	})
	const [params, setParams] = useSearch<TFilters>({
		...query,
		month: query.month || startOf(new Date(), "month"),
	})
	useEffect(() => {
		setQuery(params)
	}, [params, setQuery])
	const requestParams = useMemo(() => {
		return {
			q: params.q || null,
			start_date: dateToUTCString(startOf(params.month, "month")),
			end_date: dateToUTCString(endOf(params.month, "month")),
		}
	}, [params])
	const xhr = useXHR()
	const { data, error } = useSWR(
		`/tenant-active-users/${tenantUid}${queryToSearch(requestParams)}`,
		() => TenantsXHR(xhr).getSubscriptionActiveUsers(tenantUid, requestParams)
	)

	const { hasPermission } = useCheckPermissions()

	const canManageTenants = hasPermission(PERMISSIONS.MANAGE_TENANTS)

	return (
		<Box>
			<Tabs>
				<TabsList>
					{[
						new Date(),
						subtractUnit(new Date(), 1, "month"),
						subtractUnit(new Date(), 2, "month"),
						subtractUnit(new Date(), 3, "month"),
						subtractUnit(new Date(), 4, "month"),
						subtractUnit(new Date(), 5, "month"),
						subtractUnit(new Date(), 6, "month"),
						subtractUnit(new Date(), 7, "month"),
						subtractUnit(new Date(), 8, "month"),
						subtractUnit(new Date(), 9, "month"),
						subtractUnit(new Date(), 10, "month"),
						subtractUnit(new Date(), 11, "month"),
					]
						.filter((date) => {
							return isSameOrAfter(endOf(date, "month"), signupAt)
						})
						.map((month) => (
							<TabItem
								key={getUnit(month, "month")}
								active={isSame(params.month, month, "month")}
								title={formatDate(month, "MMMM YYYY")}
								onClick={() =>
									setParams({
										...params,
										month: month,
									})
								}
							>
								{formatDate(month, "MMM, YY")}
							</TabItem>
						))}
				</TabsList>
				<TabContent>
					<Container paddingY="4">
						{!data ? (
							error ? (
								<Alert status="error">{error.message}</Alert>
							) : (
								<Spinner padding="4" alignCenter />
							)
						) : (
							<Stack gap="6">
								<Box
									bgColor="default"
									borderWidth="1"
									boxShadow="lg"
									rounded="lg"
									paddingY="8"
									paddingX="4"
								>
									<Grid>
										<Col>
											<Attribute prop="Total Active Users">
												<Box fontSize="2xl">{data.total_active_users}</Box>
											</Attribute>
										</Col>
										{data.total_new_signups ? (
											<Col>
												<Attribute prop="New Signup(s)">
													<Box fontSize="2xl">{data.total_new_signups}</Box>
												</Attribute>
											</Col>
										) : null}
										{canManageTenants ? (
											<>
												<Col>
													<Attribute
														prop={`Billing Amount (${data.bill_amount?.currency || data.conversion_detail?.currency || "N/A"})`}
													>
														<Inline gap="2">
															<Box fontSize="2xl" fontWeight="semibold">
																{data.bill_amount ? (
																	<Money money={data.bill_amount} />
																) : (
																	"0"
																)}
															</Box>
															{data.conversion_detail ? (
																<Stack
																	gap="1"
																	borderLeftWidth="4"
																	borderYWidth="1"
																	borderRightWidth="1"
																	roundedRight="md"
																	paddingX="2"
																	paddingTop="1"
																	paddingBottom="2"
																	bgColor={"subtle"}
																>
																	<Box fontWeight="semibold">
																		{!data.conversion_detail.base_users ? (
																			isZeroMoney(
																				data.conversion_detail.minimum_amount
																			) ? null : (
																				<>
																					Min:{" "}
																					<Money
																						money={
																							data.conversion_detail
																								.minimum_amount
																						}
																					/>
																					{" • "}
																				</>
																			)
																		) : (
																			<>
																				{data.conversion_detail.base_amount ? (
																					<Money
																						money={
																							data.conversion_detail.base_amount
																						}
																					/>
																				) : (
																					"0"
																				)}
																				{" for "}
																				{pluralize(
																					"User",
																					data.conversion_detail.base_users,
																					true
																				)}
																				{" + "}
																			</>
																		)}
																		<Money
																			money={
																				data.conversion_detail.amount_per_unit
																			}
																		/>{" "}
																		/{" "}
																		{pluralize(
																			"User",
																			data.conversion_detail.users_per_unit ||
																				0,
																			true
																		)}
																	</Box>
																	<Inline
																		gap="1"
																		alignItems={"center"}
																		color="muted"
																	>
																		<Inline
																			alignItems={"center"}
																			title="Validity"
																		>
																			<Icons.Calendar />
																		</Inline>
																		<Text fontSize="sm">
																			<Time
																				timestamp={
																					data.conversion_detail.valid_from_date
																				}
																			/>{" "}
																			-{" "}
																			{data.conversion_detail
																				.valid_till_date ? (
																				<Time
																					timestamp={
																						data.conversion_detail
																							.valid_till_date
																					}
																				/>
																			) : (
																				"Always"
																			)}
																		</Text>

																		{data.conversion_detail.valid_till_users ? (
																			<>
																				<Text>•</Text>
																				<Inline
																					alignItems={"center"}
																					title="Validity"
																				>
																					<Icons.UserGroup />
																				</Inline>
																				<Text
																					fontSize="sm"
																					title="Valid till Users"
																				>
																					{
																						data.conversion_detail
																							.valid_till_users
																					}
																				</Text>
																			</>
																		) : null}
																		{data.conversion_detail.base_users &&
																		!isZeroMoney(
																			data.conversion_detail.minimum_amount
																		) ? (
																			<>
																				<Text>•</Text>
																				<Text fontSize="sm">
																					Min:{" "}
																					<Money
																						money={
																							data.conversion_detail
																								.minimum_amount
																						}
																					/>
																				</Text>
																			</>
																		) : null}
																	</Inline>
																</Stack>
															) : (
																<Text color="warning">
																	<Icons.Attention /> No Active/Valid
																	Subscription
																</Text>
															)}
														</Inline>
													</Attribute>
												</Col>
											</>
										) : null}
									</Grid>
								</Box>
								<Box>
									<Table
										bordered
										hover
										headers={["S.N.", "Name", "Last Activity", "Included"]}
										rows={data.active_users.map((u, index) => [
											<Inline gap="1" alignItems="center">
												<Text>{index + 1}</Text>
												{u.new_signup && u.signup_at ? (
													<Time timestamp={u.signup_at} display={"flex"}>
														{({ value }) => (
															<Badge success>
																+ {formatDate(value, "DD MMM")}
															</Badge>
														)}
													</Time>
												) : null}
												{u.new_deleted && u.deleted_at ? (
													<Time timestamp={u.deleted_at} display={"flex"}>
														{({ value }) => (
															<Badge warning>
																x {formatDate(value, "DD MMM")}
															</Badge>
														)}
													</Time>
												) : null}
											</Inline>,
											<Text>{u.name}</Text>,
											u.last_activity_at ? (
												<Component initialState={false}>
													{({ state, setState }) => (
														<>
															<Button inline onClick={() => setState(true)}>
																<Time
																	color="default"
																	format="DD MMM"
																	timestamp={u.last_activity_at}
																/>
															</Button>
															<Dialog
																open={state}
																onClose={() => setState(false)}
																title="Recent Security Logs"
															>
																<Dialog.Body>
																	<ActivityLogs
																		causerId={u.id}
																		causerType="users"
																		securityLogs
																	/>
																</Dialog.Body>
															</Dialog>
														</>
													)}
												</Component>
											) : (
												"-"
											),
											u.is_included ? (
												<Icons.OkCircleSolid color="success" />
											) : (
												"-"
											),
										])}
									/>
								</Box>
							</Stack>
						)}
					</Container>
				</TabContent>
			</Tabs>
		</Box>
	)
}

export function LoginActivites({
	tenantUid,
	signupAt,
}: {
	tenantUid: string
	signupAt: Date
}) {
	const [query, setQuery] = useLocationQuery({
		toQuery: filtersToQuery,
		fromQuery: queryToFilters,
	})
	const [params, setParams] = useSearch<TFilters>({
		...query,
		month: query.month || startOf(new Date(), "month"),
	})
	useEffect(() => {
		setQuery(params)
	}, [params, setQuery])

	const requestParams = useMemo(() => {
		return {
			q: params.q || null,
			start_date: dateToUTCString(startOf(params.month, "month")),
			end_date: dateToUTCString(endOf(params.month, "month")),
		}
	}, [params])
	const xhr = useXHR()
	const { data, error } = useSWR(
		`/tenant-login-activities/${tenantUid}${queryToSearch(requestParams)}`,
		() => TenantsXHR(xhr).getLoginActivities(tenantUid, requestParams)
	)

	const { month } = params

	const { datewiseDisplayTotalData, datewiseDisplayUserData } = useMemo(() => {
		if (!data)
			return {
				datewiseDisplayTotalData: [],
				datewiseDisplayUserData: [],
			}
		const users = data.users
		const datewiseActivities = collect(
			data.logs.map((d) => ({
				...d,
				created_at: utcTimestampToLocalDate(d.created_at),
			}))
		)
			.groupBy((d) => {
				return formatDate(d.created_at, "YYYY-MM-DD")
			})
			.toArray()
		const datewiseUniqueActivities = datewiseActivities.map((d) => {
			const date = d[0].created_at
			const uniqueLogins = collect(d)
				.unique((d) => d.causer_id)
				.toArray()
			return {
				date,
				count: uniqueLogins.length,
				logins: uniqueLogins,
			}
		})
		const keyByDate = collect(datewiseUniqueActivities).keyBy((d) =>
			formatDate(d.date, "YYYY-MM-DD")
		)
		const startDate = startOf(month, "month")
		const endDate = endOf(month, "month")
		const today = endOf(new Date(), "day")
		let date = startDate
		const datewiseDisplayTotalData: Array<{ date: Date; count: number }> = []
		while (isBefore(date, today) && isBefore(date, endDate)) {
			const value = keyByDate[formatDate(date, "YYYY-MM-DD")]
			datewiseDisplayTotalData.push({
				date,
				count: value?.count || 0,
			})
			date = addUnit(date, 1, "day")
		}
		const datewiseDisplayUserData: Array<{
			name: string
			id: number
			deleted_at?: string
			signup_at?: string
			logins: Array<{
				date: Date
				count: number
			}>
		}> = []
		users.forEach(function (user) {
			const data: (typeof datewiseDisplayUserData)[number] = {
				name: user.name,
				id: user.id,
				deleted_at: user.deleted_at,
				signup_at: user.signup_at,
				logins: [],
			}
			const startDate = startOf(month, "month")
			const endDate = endOf(month, "month")
			const today = endOf(new Date(), "day")
			let date = startDate
			while (isBefore(date, today) && isBefore(date, endDate)) {
				const value = keyByDate[formatDate(date, "YYYY-MM-DD")]
				data.logins.push({
					date,
					count: value
						? value.logins.filter((l) => l.causer_id === user.id).length
						: 0,
				})
				date = addUnit(date, 1, "day")
			}
			datewiseDisplayUserData.push(data)
		})
		return { datewiseDisplayTotalData, datewiseDisplayUserData }
	}, [data, month])

	return (
		<Box>
			<Tabs>
				<TabsList>
					{[
						new Date(),
						subtractUnit(new Date(), 1, "month"),
						subtractUnit(new Date(), 2, "month"),
						subtractUnit(new Date(), 3, "month"),
						subtractUnit(new Date(), 4, "month"),
						subtractUnit(new Date(), 5, "month"),
						subtractUnit(new Date(), 6, "month"),
						subtractUnit(new Date(), 7, "month"),
						subtractUnit(new Date(), 8, "month"),
						subtractUnit(new Date(), 9, "month"),
						subtractUnit(new Date(), 10, "month"),
						subtractUnit(new Date(), 11, "month"),
					]
						.filter((date) => {
							return isSameOrAfter(endOf(date, "month"), signupAt)
						})
						.map((month) => (
							<TabItem
								key={getUnit(month, "month")}
								active={isSame(params.month, month, "month")}
								title={formatDate(month, "MMMM YYYY")}
								onClick={() =>
									setParams({
										...params,
										month: month,
									})
								}
							>
								{formatDate(month, "MMM, YY")}
							</TabItem>
						))}
				</TabsList>
				<TabContent>
					<Container paddingY="4">
						{!data ? (
							error ? (
								<Alert status="error">{error.message}</Alert>
							) : (
								<Spinner padding="4" alignCenter />
							)
						) : (
							<Stack gap="4">
								<Stack gap="1">
									<Heading as="h4" fontSize="md">
										Daywise Logins
									</Heading>
									<Text color="muted">
										1: Login, -: No Login, +: New Account, x: Account Deleted{" "}
									</Text>
								</Stack>
								<Table
									responsive
									bordered
									stickyFirstColumn
									stickyHeader
									headers={[<>Date</>].concat(
										datewiseDisplayTotalData.map((d) => {
											const isHoliday = isSunday(d.date)
											return (
												<Box
													as="span"
													bgColor={isHoliday ? "danger_emphasis" : undefined}
													color={isHoliday ? "on_emphasis" : undefined}
													title={isHoliday ? "Holiday" : undefined}
												>
													{formatDate(d.date, "DD")}
												</Box>
											)
										})
									)}
									rows={[
										// first row of logins
										["Total"].concat(
											datewiseDisplayTotalData.map((d) => d.count.toString())
										),
									].concat(
										// individual row for every user
										datewiseDisplayUserData.map((d) =>
											[d.name].concat(
												d.logins.map((l) => {
													const deletedOnThisDate =
														d.deleted_at &&
														isSame(
															utcTimestampToLocalDate(d.deleted_at),
															l.date,
															"day"
														)
													const signupOnThisDate =
														d.signup_at &&
														isSame(
															utcTimestampToLocalDate(d.signup_at),
															l.date,
															"day"
														)
													return (
														(l.count ? "1" : "") +
														(deletedOnThisDate
															? "x"
															: signupOnThisDate
																? "+"
																: !l.count
																	? "-"
																	: "")
													)
												})
											)
										)
									)}
								/>
							</Stack>
						)}
					</Container>
				</TabContent>
			</Tabs>
		</Box>
	)
}

function Attribute({
	prop,
	children,
}: {
	prop: React.ReactNode
	children: React.ReactNode
}) {
	return (
		<Stack gap="1">
			<Box
				fontSize="sm"
				textTransform="uppercase"
				letterSpacing="wider"
				fontWeight="semibold"
				color="muted"
				whiteSpace="preserve"
			>
				{prop}
			</Box>
			<Box fontSize="md" fontWeight="semibold">
				{children}
			</Box>
		</Stack>
	)
}

export function Configuration({ tenantUid }: { tenantUid: string }) {
	const xhr = useXHR()
	const { data, mutate } = useSWR(`/tenants/${tenantUid}/configuration`, () =>
		TenantsXHR(xhr).getTenantConfiguration(tenantUid)
	)
	const { hasPermission } = useCheckPermissions()
	const canModify = hasPermission(PERMISSIONS.MANAGE_TENANTS)
	if (!data) {
		return <Spinner alignCenter padding="4" />
	}
	return (
		<Container paddingY="8">
			<Table hover bordered responsive>
				<tbody>
					{data.map((config) => (
						<tr key={config.key}>
							<TableDataCell>
								<Stack gap="1">
									<Text fontSize="md" fontWeight="semibold">
										{config.name}
									</Text>
									<Text color="muted" fontSize="sm">
										{config.description || config.name}
									</Text>
								</Stack>
							</TableDataCell>
							<TableDataCell>
								<SelectInput
									disabled={!canModify}
									value={config.value || config.default}
									onChange={(e) => {
										if (!canModify) return
										const value = e.currentTarget.value
										xhr
											.patch(`/tenants/${tenantUid}/configuration`, {
												name: config.key,
												value: value,
											})
											.then(() => mutate())
											.then(() => showSnackbar("Configuration value updated"))
											.catch((e) => {
												const error = e as Error
												alert(
													error.message ||
														"Something went wrong. Please try after sometime"
												)
											})
									}}
								>
									{config.options.map(({ name, id, deprecated }) => (
										<option key={id} value={id} disabled={deprecated}>
											{name}
										</option>
									))}
								</SelectInput>
							</TableDataCell>
						</tr>
					))}
				</tbody>
			</Table>
		</Container>
	)
}

export function UsageStats({ id }: { id: number }) {
	const xhr = useXHR()
	const [intervalType, setIntervalType] = useState<"month" | "week" | "day">(
		"month"
	)
	const [{ after, before }, setFilters] = useState(() => ({
		after: startOf(new Date(), intervalType),
		before: endOf(new Date(), intervalType),
	}))
	const requestParams = useMemo(() => {
		return {
			after: dateToUTCString(after),
			before: dateToUTCString(before),
		}
	}, [after, before])
	const { data } = useSWR(
		`/tenants/usage-stats/${id}?${queryToSearch(requestParams)}`,
		() => TenantsXHR(xhr).getTenantUsageStatus(id, requestParams)
	)
	return (
		<Container paddingY="8">
			<Stack gap="4">
				<Inline collapseBelow="sm" gap="4">
					<Box>
						<Text fontWeight="semibold" fontSize="xl">
							{formatDate(after, "ddd D MMM")} -{" "}
							{formatDate(before, "ddd D MMM")}
						</Text>
					</Box>
					<Inline gap="2">
						<Button
							onClick={() => {
								const newAfter = startOf(
									subtractUnit(after, 1, intervalType),
									intervalType
								)
								setFilters({
									after: newAfter,
									before: endOf(newAfter, intervalType),
								})
							}}
						>
							<Icons.ChevronDown rotate="90" />
						</Button>
						<SelectInput
							onChange={(e) => {
								const type = e.currentTarget
									.value as never as typeof intervalType
								setIntervalType(type)
								setFilters({
									after: startOf(new Date(), type),
									before: endOf(new Date(), type),
								})
							}}
						>
							<option value="month">Month</option>
							<option value="week">Week</option>
							<option value="day">Day</option>
						</SelectInput>
						<Button
							disabled={isAfter(before, new Date())}
							onClick={() => {
								const newAfter = startOf(
									addUnit(after, 1, intervalType),
									intervalType
								)
								setFilters({
									after: newAfter,
									before: endOf(newAfter, intervalType),
								})
							}}
						>
							<Icons.ChevronDown rotate="270" />
						</Button>
					</Inline>
				</Inline>
				<Box bgColor="default" borderWidth="1" rounded="lg" padding="4">
					{!data ? (
						<Box paddingY="4">
							<Spinner alignCenter padding="4" />
						</Box>
					) : (
						<Inline alignItems="stretch" flexWrap="wrap" gap="4">
							{(
								[
									["Enquiries", data.enquiries],
									["Quotes", data.quotes],
									["Conversions", data.conversions],
									["Revenue", data.revenue],
								] as Array<[string, number]>
							).map(([label, value]) => (
								<Box
									key={label}
									paddingX="4"
									paddingY="4"
									borderColor="default"
								>
									<Box fontWeight="semibold" color="muted">
										{label}
									</Box>
									<Text fontWeight="semibold" fontSize="3xl">
										{numberToLocalString(value)}
									</Text>
								</Box>
							))}
						</Inline>
					)}
				</Box>
			</Stack>
		</Container>
	)
}

function isSunday(date: Date) {
	return formatDate(date, "dddd").toLowerCase() === "sunday"
}
