import {
	Button,
	Inline,
	Stack,
	Divider,
	Grid,
	Col,
	Heading,
	Text,
} from "@sembark-travel/ui/base"
import {
	FileInputField,
	Form,
	PhoneInputField,
	PhoneNumberValidator,
	SelectField,
	SubmissionError,
	TextInputField,
	checkFileSize,
	checkFileTypes,
	isTruthy,
	validateFormValues,
	withServerErrors,
} from "@sembark-travel/ui/form"
import { useState } from "react"
import * as Validator from "yup"
import { SelectTripDestination, TTripDestination } from "../TripDestinations"
import { useXHR } from "@sembark-travel/xhr"
import { TTenantBrand } from "./store"
import { showSnackbar } from "@sembark-travel/ui/snackbar"
import { SelectBrandColorTheme, TBrandColorTheme } from "./BrandColorThemes"

export function AddTenantBrand({
	onSuccess,
	...props
}: Omit<React.ComponentProps<typeof NewTenantBrandForm>, "onSubmit"> & {
	onSuccess: (brand: TTenantBrand) => unknown
}) {
	const xhr = useXHR()
	return (
		<NewTenantBrandForm
			{...props}
			onSubmit={async (payload) => {
				const { data } = await xhr.post<{ data: TTenantBrand }>(
					`/tenant-brands`,
					payload
				)
				showSnackbar("Brand added successfully.")
				await Promise.resolve(onSuccess(data.data))
			}}
		/>
	)
}

type TTenantBrandFormData = {
	name: string
	short_name: string
	profile_image?: FileList
	color_theme?: TBrandColorTheme
	trip_destinations?: Array<TTripDestination>
	phone_numbers: Array<{
		country_code: string
		phone_number: string
		number: string
		is_primary?: boolean
	}>
	helpline_remarks?: string
}

export function NewTenantBrandForm({
	onSubmit,
	onCancel,
	initialValues: propInitialValues,
}: {
	onSubmit: (data: FormData) => Promise<unknown>
	onCancel: () => unknown
	initialValues?: Partial<TTenantBrandFormData>
}) {
	const [initialValues] = useState<TTenantBrandFormData>(() => ({
		name: "",
		short_name: "",
		profile_image: undefined,
		color_theme: undefined,
		trip_destinations: [],
		...propInitialValues,
		phone_numbers: propInitialValues?.phone_numbers || [
			{
				country_code: "IN",
				phone_number: "+91",
				number: "",
			},
		],
	}))
	return (
		<Form<TTenantBrandFormData>
			initialValues={initialValues}
			validate={validateFormValues(
				Validator.object().shape({
					name: Validator.string()
						.required("Please provide the registered name of the Brand")
						.max(100, "Please use 100 or less characters"),
					short_name: Validator.string()
						.required("Please provide the brand display name")
						.max(100, "Please use 100 or less characters"),
					phone_numbers: Validator.array()
						.of(PhoneNumberValidator("Phone Number", true))
						.nullable(),
					profile_image: Validator.mixed()
						.required("Please select a file")
						.nullable(false)
						.test(
							"type-is-correct",
							"Please select an png/jpeg image file",
							checkFileTypes(["image/png", "image/jpeg"])
						)
						.test(
							"size-not-big",
							"Please select a file that is less then 1 MB in size",
							checkFileSize(500)
						),

					helpline_remarks: Validator.string()
						.required("Please provide remarks/timing for helpline in vouchers")
						.max(100, "Please use 100 or less characters"),
				})
			)}
			onSubmit={withServerErrors(async (values) => {
				const data = new FormData()
				data.append("name", values.name)
				data.append("helpline_remarks", values.helpline_remarks || "")
				data.append("short_name", values.short_name)
				if (values.color_theme) {
					data.append("color_theme", values.color_theme.id)
				}
				const phone_numbers = values.phone_numbers
					?.filter((p) => String(p.number).trim())
					.map((p) => ({
						...p,
						is_primary: isTruthy(p.is_primary) ? 1 : 0,
					}))

				if (phone_numbers.length) {
					phone_numbers.forEach((number, index) => {
						data.append(
							`phone_numbers[${index}][country_code]`,
							number.country_code
						)
						data.append(
							`phone_numbers[${index}][phone_numbers]`,
							number.phone_number
						)
						data.append(
							`phone_numbers[${index}][number]`,
							String(number.number)
						)
					})
				}

				values.trip_destinations?.forEach((d, index) => {
					data.append(`trip_destinations[${index}]`, String(d.id))
				})

				if (values.profile_image) {
					data.append("profile_image", values.profile_image as never)
				}
				await onSubmit(data)
			})}
		>
			{({ submitting, handleSubmit }) => (
				<form noValidate onSubmit={handleSubmit}>
					<Grid gap="6">
						<Col sm={12} md={4}>
							<Stack>
								<Heading fontSize="md">Branding Details</Heading>
								<Text color="muted">
									Please provide brand name and it's registered namd with a
									Logo.
								</Text>
							</Stack>
						</Col>
						<Col>
							<Stack gap="4">
								<TextInputField
									name="short_name"
									label="Short/Display/Brand Name"
									type="text"
									placeholder="e.g. ABC Travel"
								/>
								<TextInputField
									name="name"
									label="Official/Billing/Registered Name"
									type="text"
									placeholder="e.g. ABC Pvt Ltd"
								/>
								<FileInputField
									label="Select a Logo image"
									name="profile_image"
									accept="image/jpeg,image/png"
									help="Image should be 400x400 JPEG/PNG file with less than 500 KB in size."
								/>
								<SelectField
									select={SelectBrandColorTheme}
									label="Branding Color Theme"
									name="color_theme"
									fetchOnMount
								/>
							</Stack>
						</Col>
					</Grid>
					<Divider sm />
					<Grid gap="6">
						<Col sm={12} md={4}>
							<Stack>
								<Heading fontSize="md">Destinations and Support</Heading>
								<Text color="muted">
									Associated Trip Destinations and general support contact
									details.
								</Text>
							</Stack>
						</Col>
						<Col>
							<Stack gap="4">
								<SelectField
									name="trip_destinations"
									label="Trip Destinations"
									select={SelectTripDestination}
									multiple
									secondaryLabel="optional"
								/>
								<PhoneInputField
									name="phone_numbers"
									multi
									label="Support Contact Number(s)"
								/>
								<TextInputField
									name="helpline_remarks"
									label="Helpline Remarks"
								/>
							</Stack>
						</Col>
					</Grid>
					<Divider sm />
					<Grid gap="0">
						<Col sm={12} md={{ offset: 4, span: 8 }}>
							<Stack gap="4" paddingLeft={{ md: "6" }}>
								<SubmissionError />
								<Inline gap="4">
									<Button type="submit" disabled={submitting}>
										{submitting ? "Creating..." : "Create Brand"}
									</Button>
									<Button onClick={() => onCancel()} level="tertiary">
										Cancel
									</Button>
								</Inline>
							</Stack>
						</Col>
					</Grid>
				</form>
			)}
		</Form>
	)
}
